@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* @keyframes pulse {
  0%, 100% {
    background-size: 100% 150%;
  }
  50% {
    background-size: 150% 100%;
  }
}

.pulse {
  animation: pulse 5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
} */


/* .pulse {
  animation: pulse 5s infinite;
} */

@keyframes pulse {
  0%, 100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.pulse {
  background-size: 400% 400%;
  animation: pulse 20s cubic-bezier(0.2, 0.9, 0.5, 1) infinite;
} 
